import React from "react"
import bg from '../../assets/bg.png'
import logo from '../../assets/logo.png'
import { Button } from 'antd-mobile';
import './index.less'

const MobileDetect = require('mobile-detect');

const Download = () => {

  const jumpStore = () => {
    const deviceType = navigator.userAgent; // 获取userAgent信息
    const md = new MobileDetect(deviceType); // 初始化mobile-detect
    const os = md.os(); // 获取系统
    if (os === 'iOS') {
      window.location.href = "https://apps.apple.com/cn/app/solace-connect/id1548426398"
    } else if (os === 'AndroidOS') {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.solace' // 南非
    }
  }

  return (
    <div className="download_container">
      <div className="download_header">
        <img className="download_header_img" src={bg} alt="" />
      </div>
      <div className="download_content">
        <div className="download_logo">
          <img className="download_logoImg" src={logo} alt="logo" />
        </div>
        <div className="download_content_title">
          TJOB
        </div>
        <div className="download_content_des">
          知名企业都在用,智能匹配海量求职者,大量同城员工在线直接联系,高效快速,招聘人才上TJOB
        </div>
        <div className="download_content_btn" onClick={() => jumpStore()}>
          点击安装
        </div>

      </div>
    </div>
  )
}

export default Download
