import React, { useEffect, useState } from "react"
import logo from './logo.svg';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Download from "./page/Download"
import './App.less';

const MobileDetect = require('mobile-detect');


const MobileWarning = () => {
  return (
    <div className="mobile_warning">
      These demos should be viewed on a mobile device, an emulator or the mobile
      view in your devtools.
    </div>
  )
}


const routes = [
  { path: "/", component: Download, title: "download" },
  { path: "/download", component: Download, title: "download" }
]

const App = () => {
  const [renderBool, setRenderBool] = useState(false)
  useEffect(() => {
    var system = {
      win: false,
      mac: false,
      xll: false
    };
    //检测平台
    var p = navigator.platform;
    system.win = p.indexOf("Win") === 0;
    system.mac = p.indexOf("Mac") === 0;
    system.x11 = (p === "X11") || (p.indexOf("Linux") === 0);
    //跳转语句
    if (system.win || system.mac || system.xll) {
      window.location.href = "http://tjob.tolern.com/#/app"
    }else{
      setRenderBool(true)
    }
  }, [])
  return  renderBool? (
    <div className="App">
      <MobileWarning />
      <Router>
        <Switch>
          {routes.map(r => {
            const Component = r.component
            return (
              <Route path={r.path} key={r.path}>
                <Component />
              </Route>
            )
          })}
        </Switch>
      </Router>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  ) : (
    <div className="App"></div>
  );
}

export default App;
